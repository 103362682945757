const empty = [
    "####################",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "####################",
];

const Level1 = [
    "####################",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#       #  #       #",
    "#      ##  ##      #",
    "#     ##    ##     #",
    "#    ##      ##    #",
    "#   ##        ##   #",
    "#  ##    ##    ##  #",
    "#  #    ####    #  #",
    "#      ##  ##      #",
    "#     ##    ##     #",
    "#    ##      ##    #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "####################",
];

const Level2 = [
    "####################",
    "#                  #",
    "#                  #",
    "#    #####         #",
    "#    #####         #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#     #######      #",
    "#     #######      #",
    "#     #######      #",
    "#     #######      #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#                  #",
    "#        ######    #",
    "#        ######    #",
    "#                  #",
    "####################",
];

const Level3 = [
    "####################",
    "#                  #",
    "#                  #",
    "#                  #",
    "#      ## ##       #",
    "#     #     #      #",
    "#    #       #     #",
    "#   ##       ##    #",
    "#   ##       ##    #",
    "#   ##       ##    #",
    "#   ###########    #",
    "#   ###########    #",
    "#   ###########    #",
    "#   ##       ##    #",
    "#   ##       ##    #",
    "#   ##       ##    #",
    "#                  #",
    "#                  #",
    "#                  #",
    "####################",
];

const Level4 = [
    "####################",
    "#                  #",
    "#       #####      #",
    "#                  #",
    "#                  #",
    "# ###############  #",
    "#                  #",
    "#                  #",
    "#  #####    #####  #",
    "#                  #",
    "#       ###        #",
    "#                  #",
    "# ###  #######     #",
    "#                  #",
    "#  ##              #",
    "#         #######  #",
    "#                  #",
    "#  #########       #",
    "#                  #",
    "####################",
];

const Level5 = [
    "####################",
    "#                  #",
    "#  ###        ###  #",
    "#  ###        ###  #",
    "#                  #",
    "#       ###        #",
    "#      #####       #",
    "#       ###        #",
    "#  ##         ##   #",
    "#   ###    ###     #",
    "#      ####        #",
    "###             ####",
    "####           ### #",
    "# #####    ######  #",
    "#   #### ####     ##",
    "#               ## #",
    "###            ##  #",
    "# ###  ####   ##   #",
    "#      ###         #",
    "####################",
];

const Level6 = [
    "####################",
    "#        ##        #",
    "#  ####  ##  ####  #",
    "#  ####  ##  ####  #",
    "#                  #",
    "#   #          #   #",
    "#   #####   ####   #",
    "#   #          #   #",
    "#                  #",
    "#  #  ####  ###  # #",
    "#  #  #       #  # #",
    "#  #  #       #  # #",
    "#  #  ####  ###  # #",
    "#  #  ####  ###  # #",
    "#                  #",
    "#  ##          ##  #",
    "#  ####  ##  ####  #",
    "#  ####  ##  ####  #",
    "#        ##        #",
    "####################",
];

export default [
    Level1,
    Level2,
    Level3,
    Level4,
    Level5,
    Level6,
];
